import {
  MAXIMUM_SPEC_DESCRIPTION_LENGTH,
  MINIMUM_DESCRIPTION_LENGTH,
} from "views/Mission/TeamSpecV2/constants";
import { SpecV3, SpecError } from "../types";
import { HiringTimeline } from "@a_team/models/dist/ClientRegistration";
import { whenToStartOptions } from "views/Mission/TeamSpecV2/ProjectDetails/constants";
import { checkRoleCompleteness } from "views/Mission/TeamSpecV2/utils/roles";
import { RegisteredUserObject } from "@a_team/models/dist/UserObject";
import * as dateFns from "date-fns";

export const checkMissionCompleteness = (
  mission: SpecV3,
  user?: RegisteredUserObject
): null | {
  errors: SpecError[];
  isComplete: boolean;
} => {
  const {
    title = "",
    description = "",
    companyDescription = "",
    roles = [],
    whenToStart,
    timezoneOverlap,
    timezone,
  } = mission;
  const titleLength = title?.length ?? 0;
  const descriptionLength = description?.length ?? 0;
  const companyDescriptionLength = companyDescription?.length ?? 0;
  const errors: SpecError[] = [];

  if (titleLength < 2) {
    errors.push({
      tag: "title",
      message: "Title is required.",
    });
  }

  if (descriptionLength < MINIMUM_DESCRIPTION_LENGTH) {
    errors.push({
      tag: "description",
      message: "Description is too short.",
    });
  }

  if (descriptionLength > MAXIMUM_SPEC_DESCRIPTION_LENGTH) {
    errors.push({
      tag: "description",
      message: "Description is too long.",
    });
  }

  if (companyDescriptionLength < MINIMUM_DESCRIPTION_LENGTH) {
    errors.push({
      tag: "companyDescription",
      message: "Company description is too short.",
    });
  }

  if (companyDescriptionLength > MAXIMUM_SPEC_DESCRIPTION_LENGTH) {
    errors.push({
      tag: "companyDescription",
      message: "Company description is too long.",
    });
  }

  // if the

  // Check there is at least one role, and that all roles are complete
  const roleErrors = roles.map((r) => checkRoleCompleteness(r, user));
  if (roles.length === 0) {
    errors.push({
      tag: "roles",
      message: "At least one role is required.",
    });
  } else if (roleErrors.some((r) => !r?.isComplete)) {
    errors.push({
      tag: "roles",
      message: "Some roles are incomplete.",
    });
  }

  // Check start date is set
  if (!whenToStart) {
    errors.push({
      tag: "whenToStart",
      message: "Planned start is required.",
    });
  }

  // If timezoneOverlap is set, then a timezone must be set
  if (timezoneOverlap && timezoneOverlap !== "No overlap" && !timezone) {
    errors.push({
      tag: "timezone",
      message: "Timezone is required when timezone overlap is set.",
    });
  }

  return {
    errors,
    isComplete: errors.length === 0,
  };
};

export const describeTimezoneOverlap = (mission: SpecV3): string => {
  const NO_RESTRICTIONS = "No timezone restrictions";
  if (!mission.timezoneOverlap) {
    return NO_RESTRICTIONS;
  }

  const hours = parseInt(mission.timezoneOverlap.split(" ")[0], 10);

  if (!hours) {
    return NO_RESTRICTIONS;
  }

  const cityName = mission.timezone?.name.split("/")[1].replace(/_/g, " ");

  return `${
    isNaN(hours) ? "No overlap needed" : hours + " hours overlap"
  }  with ${cityName ?? mission.timezone?.name ?? "your timezone"}`;
};

export const describePlannedStart = (
  whenToStart?: HiringTimeline,
  startDate?: Date | string
): string => {
  const start = startDate ? new Date(startDate) : undefined;

  // If the start date is withing one week before or after today, show "Immediately"
  if (
    start &&
    dateFns.isWithinInterval(start, { start: new Date(), end: new Date() })
  ) {
    return "immediately";
  }

  if (start && start < new Date()) {
    return dateFns.formatDistanceToNowStrict(start, {
      addSuffix: true,
    });
  }

  const selected = whenToStartOptions.find((o) => o.value === whenToStart);
  return selected?.shortLabel ?? "Unknown";
};
