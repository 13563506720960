import {
  ALLOWED_MINIMUM_COMMITMENT,
  MAXIMUM_ROLE_DESCRIPTION_LENGTH,
  MINIMUM_DESCRIPTION_LENGTH,
} from "views/Mission/TeamSpecV2/constants";
import { RoleV3, RoleError } from "../types";
import { isString } from "lodash";
import { validateMarkup } from "components/Roles/Role/Edit/RoleMarkup";
import { RegisteredUserObject } from "@a_team/models/dist/UserObject";
import { MissionRoleStatus } from "@a_team/models/dist/MissionRole";

export const mapRoleStatusToHumanReadable = (status?: MissionRoleStatus) => {
  switch (status) {
    case "Active":
      return "Active role";
    case "ScheduledToEnd":
      return "Scheduled to end";
    case "Ended":
      return "Ended role";
    case "Canceled":
      return "Canceled role";
    case "Open":
      return "Hiring";
    default:
      return "Ready to publish";
  }
};

export const simpleHumanizeStatus = (status?: MissionRoleStatus) => {
  if (status === MissionRoleStatus.ScheduledToEnd) {
    return "Scheduled to end";
  }
  if (status) {
    return status;
  }

  return "Inactive";
};

export const checkRoleCompleteness = (
  role?: RoleV3 | null,
  user?: RegisteredUserObject
): null | {
  errors: RoleError[];
  isComplete: boolean;
} => {
  if (!role) {
    return null;
  }

  const {
    description = "",
    category,
    minimumCommitment = NaN,
    requiredSkills = [],
  } = role;
  const descriptionLength = description?.length ?? 0;
  const errors: RoleError[] = [];

  if (!isString(category)) {
    errors.push({
      tag: "category",
      message: "Category is required.",
    });
  }

  if (!ALLOWED_MINIMUM_COMMITMENT.includes(minimumCommitment)) {
    errors.push({
      tag: "minimumCommitment",
      message: "Invalid minimum commitment.",
    });
  }

  if (descriptionLength < MINIMUM_DESCRIPTION_LENGTH) {
    errors.push({
      tag: "description",
      message: "Description is too short.",
    });
  }

  if (descriptionLength > MAXIMUM_ROLE_DESCRIPTION_LENGTH) {
    errors.push({ tag: "description", message: "Description is too long." });
  }

  if (requiredSkills.length === 0) {
    errors.push({
      tag: "requiredSkills",
      message: "Required skills are required.",
    });
  }

  // A budget type must be selected
  if (typeof role.budgetType === "undefined") {
    errors.push({
      tag: "budgetType",
      message: "Budget type is required.",
    });
  }

  // Budget amount must be set
  if (typeof role.budget === "undefined") {
    errors.push({
      tag: "budget",
      message: "Budget is required.",
    });
  }

  if (user?.isAdmin) {
    const { isValid, errorMessage } = validateMarkup(
      role._PRIVATE_ROLE_MARGIN,
      true
    );
    if (!isValid) {
      errors.push({
        tag: "_PRIVATE_ROLE_MARGIN",
        message: errorMessage || "Invalid role markup.",
      });
    }
  }

  return {
    errors,
    isComplete: errors.length === 0,
  };
};

export const describeCommitment = (commitment: number = 0) => {
  if (commitment >= 40) {
    return "Full-time hours, 40+";
  }

  switch (commitment) {
    case 5:
      return "Flexible hours, 5h - 10h";
    case 10:
      return "Flexible hours, 10h - 20h";
    case 20:
      return "Flexible hours, 20h - 30h";
    case 30:
      return "Flexible hours, 30h - 40h";
    case commitment && commitment > 0:
      return `${commitment} hours/week`;
    default:
      return undefined;
  }
};
export function formatToUSD(amount: number): string {
  const formatted = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);

  // Remove the cents if it's .00
  return formatted.endsWith(".00") ? formatted.slice(0, -3) : formatted;
}

export const describeBudget = (role: RoleV3) => {
  const { budget, budgetType = "Hourly" } = role;

  if (!budget) {
    return "Budget not specified";
  }

  return `${budgetType} role budget ${formatToUSD(budget)}`;
};
