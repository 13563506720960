import cloneDeep from "lodash/cloneDeep";
import every from "lodash/every";
import trim from "lodash/trim";
import Store from "models/Store";
import { RootStore } from "store";

import type MissionSpec from "models/MissionSpec";
import {
  type LocalCollaborators,
  type MissionSpecErrors,
  type Role,
  RoleRevisionApprovalStatus,
} from "models/MissionSpec";

import { capitalizeFirstChar, generateObjectId } from "helpers/strings";
import {
  approveRoleRevision,
  confirmSpec,
  createMissionSpec,
  createRoleRevision,
  createTalentSkill,
  declineRoleRevision,
  deleteMissionSpec,
  deleteRoleRevision,
  getMissionSpec,
  getRoleCategories,
  processVideoUpload,
  publishMission,
  updateMission,
  updateMissionSpec,
  updateRoleRevision,
} from "services/missionSpec";

import MissionRole, {
  ClientRoleQuestion,
  MissionRoleId,
  MissionRoleStatus,
} from "@a_team/models/dist/MissionRole";
import { TalentSkill } from "@a_team/models/dist/TalentCategories";
import UserObject, { UserId } from "@a_team/models/dist/UserObject";
import { clone, isEmpty, omit, uniq } from "lodash";

import { DateISOString, QueryResult } from "@a_team/models/dist/misc";
import MissionObject, {
  MissionStatus,
} from "@a_team/models/dist/MissionObject";
import { SelectOption } from "@a_team/ui-components";
import { InfiniteData } from "@tanstack/react-query";
import { FileInfo } from "@uploadcare/react-widget";
import { validateMarkup } from "components/Roles/Role/Edit/RoleMarkup";
import presets from "components/TeamPresets/presets";
import { Flags } from "configs/featureFlags";
import { Role as AccountRole } from "configs/role";
import { optimisticUpdateInfiniteQuery } from "helpers/queries";
import { getMissionSpecRole } from "helpers/role";
import { getBaseSpecificity } from "hooks/useQueryKeyAuth";
import {
  action,
  comparer,
  computed,
  type IObservableArray,
  makeObservable,
  observable,
  reaction,
  toJS,
} from "mobx";
import { AccountMember } from "models/Account";
import RoleCategory from "models/RoleCategory";
import { PresetID } from "models/Solution";
import queryKeys from "queries/keys";
import { getUserByUsername } from "services/user";
import { isMissionRole } from "../helpers/types";

export type MissionHydration = {
  missionSpec: MissionSpec;
  roleCategories?: RoleCategory[];
  selectedPaymentCycleId: string;
  builders: UserObject[];
};

export interface PagedList<T> {
  items: Array<T>;
  next?: string;
}

export type RoleFilterOptions =
  | "Active"
  | "Open"
  | "Canceled"
  | "Ended"
  | "Pending";

export const roleFilterLabel: Record<RoleFilterOptions, string> = {
  Active: "Active roles",
  Open: "Open roles",
  Canceled: "Canceled roles",
  Ended: "Ended roles",
  Pending: "Pending roles",
};

export interface IAddRoleModalData {
  roles: Role[];
  newIds: string[];
  roleTitle?: string | undefined;
}

export const defaultAddRoleModalData: IAddRoleModalData = {
  roles: [],
  newIds: [],
  roleTitle: "",
};

export const ROLE_DESCRIPTION_LIMIT = 300;
export const ROLE_QUESTION_LIMIT = 200;
export const ROLE_DESCRIPTION_MINIMAL_LENGTH = 20;

interface DateOptions {
  year: "numeric" | "2-digit" | undefined;
  month: "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined;
  day: "numeric" | "2-digit" | undefined;
}

export class MissionSpecStore implements Store {
  rootStore: RootStore;
  /** @deprecated */
  @observable public missionSpec?: MissionSpec;
  @observable public serverVersionMissionSpec?: MissionSpec;
  @observable public updaterData: any = {};
  @observable public role?: Role;
  @observable public roleFilters?: RoleFilterOptions[] = undefined;
  @observable public currentRole?: Role;
  @observable public roleFormSubmitted?: boolean;
  @observable public roleCategories?: RoleCategory[];
  @observable private lastChangeTs?: number;
  @observable private updateTimeout?: number;
  @observable public collaborators?: LocalCollaborators;
  @observable public nextToken: string | null = null;
  @observable public showMissionSpecErrors: boolean = false;
  // published mission data

  @observable selectedPaymentCycleId?: string;
  @observable timesheetsView?: "single" | "team" | MissionRoleId;
  @observable public roleDuplicateIds: IObservableArray<string> =
    observable<string>([]);
  @observable public builders: Record<UserId, UserObject> = {};
  @observable public currentBuilderId?: string;
  @observable public addRoleModalData: IAddRoleModalData =
    defaultAddRoleModalData;
  @observable public presetId?: PresetID;
  @observable public isLocalSpecOutOfSync: boolean = false;
  @observable propagateUpdate: boolean = true;
  @observable public newSpecInitialData: Partial<MissionSpec> = {};
  @observable public specDataOverrides: Partial<MissionSpec> = {};
  @observable public loading = false;

  ROLE_FILTER_OPTIONS: SelectOption[] = [
    { label: roleFilterLabel.Active, value: "Active" },
    { label: roleFilterLabel.Open, value: "Open" },
    { label: roleFilterLabel.Canceled, value: "Canceled" },
    { label: roleFilterLabel.Pending, value: "Pending" },
    { label: roleFilterLabel.Ended, value: "Ended" },
  ];

  public constructor(rootStore: RootStore, initialData?: MissionHydration) {
    this.rootStore = rootStore;

    if (initialData) {
      this.missionSpec = initialData.missionSpec;
      this.roleCategories = initialData.roleCategories;
      this.selectedPaymentCycleId = initialData.selectedPaymentCycleId;
      // this.timesheetsView = initialData.timesheetsView;
    }

    makeObservable(this);

    if (typeof window !== "undefined") {
      reaction(
        () => this.missionSpec,
        (current) =>
          current && !this.isLocalSpecOutOfSync && this.setLastChangeTs(),
        {
          equals: (prev, curr) => {
            if (!prev || prev.mid !== curr?.mid) {
              return true;
            }

            const [current, modified] = [prev, curr].map(
              (it) => omit(it, ["createdAt", "pendingRoles"]) as MissionSpec
            );

            return comparer.structural(current, modified);
          },
        }
      );
      reaction(() => this.lastChangeTs, this.handleUnsaved);
      reaction(
        () => this.roleDuplicateIds.slice(),
        this.handleRoleDuplicateRemove,
        { delay: 5000 }
      );
    }
  }

  @computed get missionRoleAvailability(): string {
    if (!this.currentBuilder) {
      return "Not Available";
    }
    const availabilityObj = this.currentBuilder.availability;

    const options: DateOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    switch (availabilityObj?.type) {
      case "FutureDate":
        return `Available from ${new Date(
          availabilityObj?.availableFrom!
        ).toLocaleDateString("en-US", options)}, for ${
          availabilityObj.weeklyHoursAvailable
        } hr/wk`;
      case "Now":
        return `Available for ${availabilityObj.weeklyHoursAvailable} hr/wk`;
      case "NotAvailable":
      default:
        return "Not Available";
    }
  }

  @computed get missionRoleSkills(): string[] {
    if (!this.currentBuilder) {
      return [];
    }
    const skillsObj = this.currentBuilder.talentProfile?.talentSkills;
    if (skillsObj) {
      const { mainTalentSkills, additionalTalentSkills } = skillsObj;

      const mainSkills = mainTalentSkills.length
        ? mainTalentSkills.map((skill) => skill.talentSkillName)
        : [];

      const nonFalsyMainSkills = mainSkills.flatMap((skill) =>
        skill ? [skill] : []
      );

      const additonalSkills = additionalTalentSkills.length
        ? additionalTalentSkills?.map((skill) => skill.talentSkillName)
        : [];

      const nonFalsyAdditionalSkills = additonalSkills.flatMap((skill) =>
        skill ? [skill] : []
      );
      return nonFalsyMainSkills.concat(nonFalsyAdditionalSkills);
    } else {
      return [];
    }
  }

  @computed get currentRoleHourlyRate(): string {
    const role = this.role || this.currentRole;
    return role?.clientDisplayRate
      ? `$${role.clientDisplayRate} / hour`
      : "N/A";
  }

  @computed get missionRoleLocation(): string {
    if (!this.currentBuilder) {
      return "N/A";
    }
    const location = this.currentBuilder.location;
    if (location) {
      const { city, country } = location;
      return city && country
        ? `${capitalizeFirstChar(city)}, ${capitalizeFirstChar(country)}`
        : "N/A";
    } else {
      return "N/A";
    }
  }

  @computed get missionRoleTimezone(): string {
    if (!this.currentBuilder) {
      return "N/A";
    }
    const timezone = this.currentBuilder.timezone;
    if (timezone) {
      const { name, utcOffset } = timezone;
      const sign = utcOffset < 0 ? "+" : "-";
      const utcOffsetInHours = Math.floor(Math.abs(utcOffset / 60));
      const utcOffsetMinutes = Math.abs(utcOffset / 60) % 1 ? "30" : "00";
      return name && utcOffset
        ? `${name}, (GMT${sign}${utcOffsetInHours}:${utcOffsetMinutes})`
        : "N/A";
    } else {
      return "N/A";
    }
  }

  @computed get canRequestExtension(): boolean {
    if (!this.missionSpec || !this.missionSpec?.mission) {
      return false;
    }
    switch (this.missionSpec.mission.status) {
      case MissionStatus.Created:
      case MissionStatus.Published:
      case MissionStatus.Pending:
      case MissionStatus.Running:
        return true;
      default:
        return false;
    }
  }

  @computed get roleQuestionsValid(): boolean {
    return this.role?.customQuestions
      ? this.role?.customQuestions?.every(
          (question) => question.text.length <= ROLE_QUESTION_LIMIT
        )
      : true;
  }

  public internalRoleEmpty = (role: Role): boolean => {
    if (!role?.isInternal) {
      return false;
    }
    return (
      !role?.internalTeamMember?.email || !role?.internalTeamMember?.fullName
    );
  };

  // Validates a roles margin based on the feature flag, isAdmin status and the role itself
  public roleHasValidMargin = (role: Role | undefined): boolean => {
    // Non-admins won't have access to private fields
    if (!this.rootStore.userStore.isAppInAdminMode) {
      return true;
    }

    const hasCustomRoleMarkupsEnabled =
      this.rootStore.userStore.flagOpenForUser(Flags.CustomRoleMarkups);

    return validateMarkup(
      role?._PRIVATE_ROLE_MARGIN,
      hasCustomRoleMarkupsEnabled
    )?.isValid;
  };

  public roleIsValid = (role: Role | undefined): boolean => {
    return !!(
      role &&
      !this.internalRoleEmpty(role) &&
      role?.category &&
      role?.requiredSkills?.length &&
      role.description &&
      role.description.length <= ROLE_DESCRIPTION_LIMIT &&
      role.description.length >= ROLE_DESCRIPTION_MINIMAL_LENGTH &&
      (role.clientRateMin && role.clientRateMax
        ? role.clientRateMin <= role.clientRateMax
        : true) &&
      ((role.clientRateMin && role.clientRateMax) ||
        (!role.clientRateMin && !role.clientRateMax)) &&
      (role?.clientRateMin === undefined || role.clientRateMin > 0) &&
      this.roleQuestionsValid &&
      this.roleHasValidMargin(role)
    );
  };

  @computed get roleFiltersOptions(): SelectOption[] {
    return (this.roleFilters || []).map((status) => {
      return {
        value: status,
        label: `${roleFilterLabel[status]}`,
      };
    });
  }

  @computed get roleFiltersPlaceholder(): string {
    if (!this.roleFilters?.length) {
      return "Status";
    }
    return this.roleFilters.length !== this.ROLE_FILTER_OPTIONS.length
      ? `${this.roleFilters
          .map((status) => roleFilterLabel[status])
          .join(", ")}`
      : "All roles";
  }

  @computed get roleSubmitDisabled(): boolean {
    return this.isPublished || !this.roleIsValid(this.role);
  }

  @computed get currentBuilder(): UserObject | undefined {
    if (!this.currentBuilderId) {
      return undefined;
    }
    return this.builders[this.currentBuilderId];
  }

  @action setRoleAddModalData = (data: IAddRoleModalData | null) => {
    if (data === null) {
      data = defaultAddRoleModalData;
    }
    this.addRoleModalData = data;
  };

  @action setCurrentBuilderId = (builderId?: UserId) => {
    this.currentBuilderId = builderId;
  };

  @action unsetMission = () => {
    this.missionSpec = undefined;
    this.serverVersionMissionSpec = undefined;
    this.selectedPaymentCycleId = undefined;
    this.resetLastChangeTs();
    this.builders = {};
    this.presetId = undefined;
    this.setCurrentBuilderId(undefined);
    this.addRoleModalData = defaultAddRoleModalData;
    this.patchMissionsCache();
    this.setRoleFilters(undefined);
  };

  @action newMission = () => {
    this.missionSpec = {
      status: "spec",
      roles: [],
      mid: "",
      ...(clone(this.newSpecInitialData) || {}),
    };
    this.serverVersionMissionSpec = undefined;
    this.newSpecInitialData = {};
  };

  updateMissionSpecAndCleanup = (
    updated: MissionSpec & { publishedAt?: DateISOString },
    preventReload?: boolean
  ) => {
    if (updated) {
      new Promise((res) => {
        const { updatedAt, mission } = updated;
        this.missionSpec = {
          ...(this.missionSpec as MissionSpec),
          updatedAt,
          mission,
        };
        this.serverVersionMissionSpec = undefined;
        res(true);
      }).then(() => {
        this.resetLastChangeTs();
        if (this.isLocalSpecOutOfSync) {
          this.isLocalSpecOutOfSync = false;

          // if data was out of sync, refresh page to reflect changes
          !preventReload && window.location.reload();
        }
      });
    }
  };

  @action public preserveServerVersionMissionSpec = (spec: MissionSpec) => {
    // if the original mission spec data from the server hasn't been saved, do it
    if (!this.serverVersionMissionSpec) {
      this.serverVersionMissionSpec = spec;
    }
  };

  @action public overrideServerMission = async (preventReload?: boolean) => {
    if (this.missionSpec?.mid) {
      const updatedMission = await this.update({
        ...this.missionSpec,
        override: true,
      });
      this.updateMissionSpecAndCleanup(
        updatedMission as MissionSpec,
        preventReload
      );
    }
  };

  @action public refreshLocalMission = async () => {
    if (this.missionSpec?.mid) {
      try {
        this.setLoading(true);
        const refreshedMission = await getMissionSpec(
          this.rootStore.authStore,
          this.missionSpec.mid,
          true
        );
        if (refreshedMission) {
          this.updateMissionSpecAndCleanup(refreshedMission);
        }
      } finally {
        this.setLoading(false);
      }
    }
  };

  @action updateMission = (payload: Partial<MissionSpec>) => {
    if (!this.missionSpec) {
      return;
    }

    this.missionSpec = {
      ...this.missionSpec,
      ...payload,
    };
  };

  @action public setMissionSpecErrorsVisibility = (visible: boolean): void => {
    this.showMissionSpecErrors = visible;
  };

  @action setPresetId = (id: PresetID) => {
    this.presetId = id;
  };

  @action renewDraftMissionSpec = () => {
    if (this.missionSpec?.status !== "spec") {
      throw new Error(
        `You cannot reset a mission with status ${this.missionSpec?.status}`
      );
    }

    this.setMission({ roles: [], status: "spec" });
    this.setPresetId(PresetID.CUSTOM);
    this.resetLastChangeTs();
  };

  @action setSelectedPaymentCycleId = (yid: string): void => {
    this.selectedPaymentCycleId = yid;
  };

  @computed get currentUserRole(): Role | undefined {
    return this.missionSpec?.roles.find(
      (role: Role) =>
        (role as any).user?.username &&
        (role as any).user?.uid === this.rootStore.userStore.user?.uid
    );
  }

  @action setBuilders = (builder: UserObject): void => {
    const buildersObj = { ...this.builders };
    buildersObj[builder.uid] = { ...builder };
    this.builders = {
      ...buildersObj,
    };
  };

  @action setPropagateUpdate = (propagateUpdate: boolean): void => {
    this.propagateUpdate = propagateUpdate;
  };

  @action setMission = (
    mission: MissionSpec,
    propagateUpdate: boolean = true
  ): void => {
    this.missionSpec = {
      ...(mission?.clientCompany && {
        companyDescription: mission.clientCompany.description,
        logo: mission.clientCompany.logo,
      }),
      ...mission,
    };
    this.setPropagateUpdate(propagateUpdate);
    this.resetLastChangeTs();
    this.patchMissionsCache();

    if (!isEmpty(this.specDataOverrides)) {
      this.updateMission(clone(this.specDataOverrides));
      this.setLastChangeTs();
      this.specDataOverrides = {};
    }
  };

  @action setDefaultRoleFilters = () => {
    if (this.roleFilters?.length) {
      return;
    }
    const activeRolesExist = (this.missionSpec?.roles || []).find(
      (role) =>
        role?.status === MissionRoleStatus.Active ||
        role?.status === MissionRoleStatus.ScheduledToEnd
    );

    this.setRoleFilters(
      activeRolesExist ? [MissionRoleStatus.Active, MissionRoleStatus.Open] : []
    );
  };

  public invalidateMissionCache = (): void => {
    const specificity = getBaseSpecificity(
      this.rootStore.authStore.token,
      this.rootStore.accountsStore.currentAccountId
    );

    this.rootStore.queryClient.invalidateQueries({
      queryKey: queryKeys.missionSpecs.pending(specificity).queryKey,
      exact: false,
      refetchType: "all",
    });
    this.rootStore.queryClient.invalidateQueries({
      queryKey: queryKeys.missionSpecs.running(specificity).queryKey,
      exact: false,
      refetchType: "all",
    });
    this.rootStore.queryClient.invalidateQueries({
      queryKey: queryKeys.missionSpecs.titles(specificity).queryKey,
      exact: false,
    });
    this.rootStore.queryClient.invalidateQueries({
      queryKey: queryKeys.missionSpecs.requestedCount(specificity).queryKey,
      exact: false,
    });
  };

  public addMissionRoleForUser = (
    role: AccountRole,
    missionSpecId: string,
    accountId?: string,
    userId?: string
  ) => {
    const {
      authStore: { token },
      userStore: { user },
      accountsStore: { currentAccountId },
      queryClient,
    } = this.rootStore;

    // Use arguments if provided
    userId = userId || user?.uid;
    accountId = accountId || currentAccountId;

    if (userId && accountId) {
      const specificity = getBaseSpecificity(token, accountId);
      const queryKey = queryKeys.accounts.collaborators(specificity).queryKey;
      queryClient.setQueryData(
        queryKey,
        (members: AccountMember[] | undefined) => {
          return members?.map((m) => {
            if (m.user?.id === userId) {
              m.roles = uniq(
                (m.roles || []).concat(`${role}:${missionSpecId}` as any)
              );
            }
            return m;
          });
        }
      );

      queryClient.invalidateQueries(queryKey);
    }
  };

  // Also removing a mission role should revoke
  // removing a mission should revoke all mission roles

  public patchMissionsCache = (): void => {
    const missionSpec = this.missionSpec;

    if (!missionSpec || !missionSpec.id) {
      return;
    }
    const comparer = (item: MissionSpec) => item.id === missionSpec.id;

    this.rootStore.queryClient.setQueriesData(
      queryKeys.missionSpecs.running({}),
      (data: InfiniteData<QueryResult<MissionSpec>> | undefined) =>
        optimisticUpdateInfiniteQuery(data, missionSpec, comparer)
    );
    this.rootStore.queryClient.setQueriesData(
      queryKeys.missionSpecs.pending({}),
      (data: InfiniteData<QueryResult<MissionSpec>> | undefined) =>
        optimisticUpdateInfiniteQuery(data, missionSpec, comparer)
    );
    this.rootStore.queryClient.setQueriesData(
      queryKeys.missionSpecs.byId({}),
      (data: MissionSpec | undefined) =>
        missionSpec.id === data?.id ? missionSpec : data
    );
  };

  @action setRoleIsNew = (roleId: string, isApproved = false): void => {
    if (this.missionSpec) {
      this.missionSpec.pendingRoles = this.missionSpec.pendingRoles?.map(
        (pending) => {
          if (pending._id === roleId || pending.rid === roleId) {
            pending.isNew = true;
            pending.isApproved = isApproved;
          }
          return pending;
        }
      );
    }
  };

  @action setCurrentRole = (role?: Role): void => {
    this.currentRole = role;
  };

  @action unsetCurrentRole = () => {
    this.currentRole = undefined;
  };

  @action setRole = (role: Role): void => {
    this.role = role;
    this.roleFormSubmitted = false;
  };

  @action setRoleFormSubmitted = (submitted: boolean): void => {
    this.roleFormSubmitted = submitted;
  };

  @action setRoleFilters = (roleFilters?: RoleFilterOptions[]): void => {
    this.roleFilters = roleFilters;
  };

  @action unsetRole = () => {
    this.role = undefined;
  };

  @action addRole = (): string | undefined => {
    if (!this.missionSpec) {
      return;
    }

    const blankRole = this.getBlankRole();

    this.missionSpec = {
      ...this.missionSpec,
      roles: [...this.missionSpec.roles, blankRole],
    };

    return blankRole.rid;
  };

  @action addRoleRevision = (): string | undefined => {
    if (!this.missionSpec) {
      return;
    }

    const blankRole = this.getBlankRole();

    this.setRole(blankRole);

    this.missionSpec = {
      ...this.missionSpec,
      pendingRoles: [...(this.missionSpec.pendingRoles || []), blankRole],
    };

    return blankRole.rid;
  };

  @action deleteRole = (rid: string): void => {
    if (!this.missionSpec) {
      return;
    }

    this.missionSpec = {
      ...this.missionSpec,
      roles: this.missionSpec?.roles.filter((role) => role.rid !== rid),
    };
  };

  @action deletePendingRole = (rid: string): void => {
    if (
      !this.missionSpec ||
      !this.missionSpec.pendingRoles ||
      !this.missionSpec.platformId
    ) {
      return;
    }

    this.missionSpec = {
      ...this.missionSpec,
      pendingRoles: this.missionSpec?.pendingRoles.filter(
        (role) => role.rid !== rid
      ),
    };

    const promise = deleteRoleRevision(
      this.rootStore.authStore,
      this.missionSpec.platformId!,
      rid
    );

    this.rootStore.uiStore.setThenable(promise);
    promise.catch(this.rootStore.errorStore.addError);
  };

  @action duplicateRole = async (role: Role | MissionRole): Promise<void> => {
    if (!this.missionSpec || isMissionRole(role)) {
      return;
    }

    const rid = generateObjectId();

    const duplicatedRole = {
      ...cloneDeep(role),
      _id: rid,
      id: rid,
      rid: rid,
    };

    this.updateMission({
      roles: [...this.missionSpec.roles, duplicatedRole],
    });

    this.rootStore.uiStore.setToast({
      text: "The role was duplicated.",
      type: "success",
    });

    this.addRolesDuplicateId(rid);
  };

  @action hydrate() {
    return JSON.stringify(toJS(this));
  }

  @action updateRole = (payload: Partial<Role>) => {
    if (!this.role) {
      return;
    }
    this.role = {
      ...this.role,
      ...payload,
    };
  };

  @action updateMissionRoleById = (rid: string, payload: Role) => {
    if (!this.missionSpec) {
      return;
    }

    const roles = this.updateRoleById(this.missionSpec.roles, rid, payload);

    this.updateMission({ roles });
  };

  @action updatePendingRoleById = (
    rid: string,
    payload: Role,
    pushToServer = true
  ) => {
    if (
      !this.missionSpec ||
      !this.missionSpec.pendingRoles ||
      !this.missionSpec.mission
    ) {
      return;
    }

    // Make a snapshot ref so we can restore it on fail
    const originalPendingRoles = this.missionSpec.pendingRoles || [];
    const missionRoles = this.missionSpec?.roles || [];
    const allRoles = [...originalPendingRoles, ...missionRoles];

    const roleIds = allRoles.map((it) => it.rid);
    const isNew = !roleIds.includes(rid);
    const mid = this.missionSpec.mission.mid;

    const newPendingRoles = this.updateRoleById(
      [...this.missionSpec.pendingRoles],
      rid,
      payload
    );

    this.updateMission({ pendingRoles: newPendingRoles });

    if (!pushToServer) {
      return;
    }

    const promise = isNew
      ? this.createRoleRevision(mid, payload)
      : this.updateRoleRevision(mid, payload);

    this.rootStore.uiStore.setThenable(promise);

    promise.catch((error) => {
      this.rootStore.errorStore.addError(error);
      this.updateMission({ pendingRoles: originalPendingRoles });
    });
  };

  @action resetMissionRoleLead = () => {
    if (!this.missionSpec) {
      return;
    }

    this.missionSpec.roles = this.missionSpec.roles.map((role) => ({
      ...role,
      isLead: false,
    }));
  };

  @action setRoleCategories = (roleCategories: RoleCategory[]): void => {
    this.roleCategories = roleCategories;
  };

  public loadRoleCategories(): Promise<void> {
    const { authStore } = this.rootStore;
    return getRoleCategories(authStore).then(this.setRoleCategories);
  }

  @action public createSkills = (
    skills: TalentSkill[]
  ): Promise<TalentSkill[] | void> => {
    const promises = skills.map((skill) => {
      return createTalentSkill(
        this.rootStore.authStore,
        skill.name,
        skill.talentCategoryIds
      );
    });

    return Promise.all(promises)
      .then((values) => {
        return values;
      })
      .catch(() => {
        this.rootStore.uiStore.setToast({
          text: "Unable to create skill",
          type: "error",
        });
      });
  };

  @action public addRolesDuplicateId = (id: string) => {
    this.roleDuplicateIds.push(id);
  };

  @action public handleRoleDuplicateRemove = (ids: string[]) => {
    if (ids.length > 0) {
      this.roleDuplicateIds.splice(0, 1);
    }
  };

  /** @deprecated */
  @computed public get isPublished(): boolean | undefined {
    return this.missionSpec
      ? this.missionSpec?.status === "published"
      : undefined;
  }

  @computed public get missionSpecErrors(): MissionSpecErrors {
    if (!this.missionSpec) {
      return {
        title: false,
        roles: false,
        startDate: false,
        description: false,
      };
    }

    return {
      title: !trim(this.missionSpec?.title || ""),
      description: !trim(
        this.missionSpec?.description?.replace(/(<(\/)?p>|&nbsp;|\n)/g, "") ||
          ""
      ),
      startDate: !this.missionSpec?.startDate,
      roles:
        !this.rolesList.length &&
        this.rolesList.every(({ category }) => !!category),
    };
  }

  @computed public get missionSpecDraftErrors(): MissionSpecErrors {
    if (!this.missionSpec) {
      return {
        title: false,
      };
    }

    return {
      title: !trim(this.missionSpec?.title || ""),
    };
  }

  @computed public get rolesList(): Array<Role> {
    if (!this.missionSpec) {
      return [];
    }
    const filters: Array<RoleFilterOptions | "ScheduledToEnd"> = [
      ...(this.roleFilters || []),
    ];

    if (this.roleFilters && this.roleFilters.length > 0) {
      // We consider ScheduledToEnd as Active
      if (this.roleFilters.includes("Active")) {
        filters.push("ScheduledToEnd");
      }
      return (this.missionSpec?.roles || []).filter(
        (role) =>
          role.status && filters.includes(role.status as RoleFilterOptions)
      );
    }

    return (this.missionSpec?.roles || []).filter((role: Role) =>
      this.roleFilters ? true : role?.status !== "Ended"
    );
  }

  @computed public get pendingRolesList(): Array<Role> {
    if (
      !this.missionSpec ||
      (!!this.roleFilters?.length && !this.roleFilters.includes("Pending"))
    ) {
      return [];
    }
    return (this.missionSpec.pendingRoles || []).filter(
      (role) =>
        role?.approvalStatus !== undefined &&
        role?.approvalStatus !== RoleRevisionApprovalStatus.Denied
    );
  }

  @computed public get displayPresets() {
    return !this.isPublished
      ? presets
      : presets.filter((preset) => preset.id === PresetID.CUSTOM);
  }

  @computed private get missionHasOwner(): boolean {
    return !!this.missionSpec?.author;
  }

  @computed public get isCurrentRoleDirty(): boolean {
    if (!this.role) {
      return false;
    }

    const { rid } = this.role;
    const specRole = [
      ...(this.missionSpec?.roles || []),
      ...(this.missionSpec?.pendingRoles || []),
    ].find((role) => role.rid === rid);

    if (!specRole) {
      return true;
    }

    return !comparer.structural(specRole, this.role);
  }

  @computed public get showPublishButton(): boolean {
    return (
      this.missionSpec?.status !== "spec" &&
      this.missionSpec?.status !== "published" &&
      this.rootStore.userStore.isAppInAdminMode
    );
  }

  @computed public get showDraftButton(): boolean {
    return !!(
      this.missionSpec &&
      ["spec", "formation"].includes(this.missionSpec?.status)
    );
  }

  @computed public get canPublish(): boolean {
    // Keep this in sync with the backend validation
    // @controllers/missionSpec.controller.ts --> looseCheckMissionValid()
    return (
      this.missionHasOwner &&
      this.rootStore.userStore.isAppInAdminMode &&
      !!this.missionSpec?.mid &&
      !this.missionSpec.platformId &&
      !!this.missionSpec.description &&
      !!this.missionSpec?.startDate &&
      this.missionSpec.roles.length > 0 &&
      !!(
        this.rootStore.accountsStore.currentAccountId ||
        this.missionSpec.clientCompany
      ) &&
      this.missionSpec.roles.every(
        (role) =>
          role.description &&
          role.description.length &&
          this.roleHasValidMargin(role)
      )
    );
  }

  @computed public get canAutoPublish(): boolean {
    return (
      this.rootStore.userStore.isAppInAdminMode &&
      Boolean(this.role?.autoPublish)
    );
  }

  @computed public get canSaveDraft(): boolean {
    return this.missionSpec?.status === "spec" && !!this.missionSpec?.mid;
  }

  public create = async (mission: MissionSpec): Promise<void> => {
    if (mission) {
      return createMissionSpec(this.rootStore.authStore, mission).then(
        (data) => {
          this.invalidateMissionCache();

          data.id &&
            this.addMissionRoleForUser(
              AccountRole.MissionAdmin,
              data.id,
              data.accountId
            );
          return this.setMission(data, true);
        }
      );
    }
  };

  private update = async (mission: MissionSpec): Promise<unknown> => {
    if (mission && mission.mid) {
      const updateFunction = mission.mission
        ? updateMission
        : updateMissionSpec;

      return updateFunction(
        this.rootStore.authStore,
        mission.mid,
        mission,
        (this.serverVersionMissionSpec as MissionSpec) || this.missionSpec
      );
    }
  };

  public remove = async (mid: string): Promise<void> => {
    if (mid) {
      return deleteMissionSpec(this.rootStore.authStore, mid).then(() => {
        this.invalidateMissionCache();
        this.unsetMission();
      });
    }
  };

  public publish = async (mission: MissionSpec): Promise<void> => {
    if (mission && mission.mid) {
      return publishMission(this.rootStore.authStore, mission).then(
        (missionRes) => {
          this.invalidateMissionCache();
          this.setMission(
            {
              ...mission,
              mission: missionRes as unknown as MissionObject,
              status: "published",
              platformId: missionRes.mid,
            },
            true
          );
        }
      );
    }
  };

  public confirm = async (missionSpec: MissionSpec): Promise<boolean> => {
    if (missionSpec && missionSpec.mid && this.isMissionValid) {
      return confirmSpec(this.rootStore.authStore, missionSpec.mid).then(
        ({ updated }) => {
          this.setMission({ ...missionSpec, ...updated, status: "formation" });
          this.setMissionSpecErrorsVisibility(false);
          return true;
        }
      );
    }
    this.setMissionSpecErrorsVisibility(true);
    return false;
  };

  public fetchBuilder = async (username: string): Promise<void> => {
    if (this.currentBuilder) {
      return;
    }
    try {
      const user = await getUserByUsername(this.rootStore.authStore, username);
      user && this.setBuilders(user);
    } catch (e) {
      throw new Error();
    }
  };

  public createRoleRevision = async (mid: string, role: Role) => {
    const promise = createRoleRevision(this.rootStore.authStore, mid, role);

    this.rootStore.uiStore.setThenable(promise);

    promise.catch(this.rootStore.errorStore.addError);

    promise.then(({ doc }: { doc: Role }) => {
      const rid = doc.rid;
      const newIds = [rid];
      const runningRoles = this.missionSpec?.roles || [];
      const pendingRoles = (this.missionSpec?.pendingRoles || [])
        .map((role) => (role.rid === rid ? doc : role))
        .filter((it) => it && it?.approvalStatus !== "Denied");

      // Push the new item into our array if it's missing
      const foundIdx = pendingRoles.findIndex((it) => it && it.rid === rid);
      if (foundIdx === -1) {
        pendingRoles.push(doc);
      }

      const newRoles = [...runningRoles, ...pendingRoles];

      if (!this.canAutoPublish) {
        this.setRoleAddModalData({
          roles: newRoles,
          newIds,
          roleTitle: role.category?.title,
        });
      }

      this.updateMission({ pendingRoles: [...pendingRoles] });
    });

    return promise;
  };

  public updateRoleRevision = async (mid: string, role: Role) => {
    const promise = updateRoleRevision(
      this.rootStore.authStore,
      mid,
      role.rid || "",
      role
    );

    this.rootStore.uiStore.setThenable(promise);

    promise.catch(this.rootStore.errorStore.addError);

    return promise;
  };

  public approveRoleRevision = async (mid: string, role: Role) => {
    const promise = approveRoleRevision(
      this.rootStore.authStore,
      mid,
      role.rid,
      { ...role, autoPublish: this.canAutoPublish }
    );

    this.rootStore.uiStore.setThenable(promise);

    promise.catch(this.rootStore.errorStore.addError);
    promise.then(this.unsetRole);

    return promise;
  };

  public declineRoleRevision = async (
    mid: string,
    role: Role,
    rejectionCategory: string,
    rejectionDetails?: string
  ) => {
    const promise = declineRoleRevision(
      this.rootStore.authStore,
      mid,
      role.rid,
      { rejectionCategory, rejectionDetails }
    );

    this.rootStore.uiStore.setThenable(promise);

    promise.catch(this.rootStore.errorStore.addError);
    promise.then((response) => {
      if (!response.doc || !response.doc?._id) {
        throw new Error("Invalid response from server:");
      }
      this.updatePendingRoleById(response.doc?.rid, response.doc, false);
    });

    return promise;
  };

  public processVideoAttachment = (info: FileInfo): void => {
    if (!this.missionSpec || !this.missionSpec.mid) {
      return;
    }

    const promise = processVideoUpload(
      this.rootStore.authStore,
      this.missionSpec?.mid,
      info
    );

    promise.catch(this.rootStore.errorStore.addError);
    this.rootStore.uiStore.setThenable(promise);
  };

  public getBlankRoleQuestion = (): ClientRoleQuestion => {
    return {
      qid: generateObjectId(),
      text: "",
      isVisible: true,
      isRequired: true,
    };
  };

  private getBlankRole = (): Role => {
    return getMissionSpecRole({
      createdBy: this.rootStore.userStore.user?.uid,
      customQuestions: [this.getBlankRoleQuestion()],
    });
  };

  private updateRoleById = (
    roles: Array<Role>,
    id: string,
    payload: Partial<Role>
  ) => {
    const updateIndex = roles.findIndex(({ rid }) => rid === id);

    if (updateIndex < 0) {
      return roles;
    }

    if (!payload.locations?.length) {
      payload.locations = null;
    }

    if (payload.locations?.length === 245) {
      payload.locations = [];
    }

    return roles.map((role, index) => {
      if (index === updateIndex) {
        return {
          ...role,
          ...payload,
        };
      }

      if (payload?.isLead) {
        return { ...role, isLead: false };
      }

      return role;
    });
  };

  @computed public get isMissionValid(): boolean {
    return every(this.missionSpecErrors as any, (value: boolean) => !value);
  }

  @computed private get isMissionSpecValid(): boolean {
    return every(
      this.missionSpecDraftErrors as any,
      (value: boolean) => !value
    );
  }

  @action private setLastChangeTs = () => {
    this.lastChangeTs = new Date().getTime();
  };

  @action private resetLastChangeTs = () => {
    this.lastChangeTs = undefined;
  };

  public saveDraft = () => {
    if (!this.canSaveDraft) {
      return;
    }
    this.handleUnsaved(this.lastChangeTs);
  };

  @action private handleUnsaved = (changeTs?: number) => {
    clearTimeout(this.updateTimeout);

    if (!this.isMissionSpecValid || !changeTs || !this.propagateUpdate) {
      this.setPropagateUpdate(true);
      return;
    }

    this.setPropagateUpdate(true);
    this.updateTimeout = window.setTimeout(() => {
      let promise;

      if (this.missionSpec?.mid) {
        promise = this.update(this.missionSpec)
          .then((response) => {
            this.updateMissionSpecAndCleanup(response as MissionSpec);
          })
          .catch((err) => {
            const { payload: { ACTION = "", updater = {} } = {} } = err;
            if (ACTION === "FORCE_RELOAD") {
              return window.location.reload();
            }
            if (
              ACTION === "UPDATE_UNSYNCED_MISSION" ||
              ACTION === "UPDATE_UNSYNCED_SPEC"
            ) {
              this.isLocalSpecOutOfSync = true;
              this.updaterData = { ...updater, ACTION };
            }
            this.rootStore.uiStore.setToast({
              text: (err?.show && err?.message) || "Failed to update spec",
              type: "error",
            });
          })
          .finally(this.patchMissionsCache);
      } else {
        promise = this.create(this.missionSpec!)
          .catch((err) => {
            console.error(err);
            this.rootStore.uiStore.setToast({
              text: "Failed to create spec",
              type: "error",
            });
          })
          .finally(this.invalidateMissionCache);
      }
      this.rootStore.uiStore.setThenable(promise);
    }, 1500);
  };

  /**
   * Only updates the `missionSpec.mission` in memory - used when we get back an updated version of `mission` from the server.
   */
  @action updateMissionObject = (mission: MissionObject) => {
    if (this.missionSpec && mission) {
      this.missionSpec.mission = mission;
    }
  };

  @action setNewSpecInitialData = (data: Partial<MissionSpec>) => {
    this.newSpecInitialData = data;
  };

  @action setSpecDataOverrides = (data: Partial<MissionSpec>) => {
    this.specDataOverrides = data;
  };

  @computed
  get missionAccountId(): string | undefined {
    return (
      this.missionSpec?.mission?.accountId || this.missionSpec?.accountId
    )?.toString();
  }

  @action setLoading = (loading: boolean) => {
    this.loading = loading;
  };
}
