/** @jsxImportSource @emotion/react */

import useLocalStorage from "@rehooks/local-storage";
import CustomToastProvider from "components/CustomToastProvider";
import { useGlobalModals } from "components/GlobalModals";
import { Flags } from "configs/featureFlags";
import { getObjectHash } from "helpers/hash";
import useCustomToast from "hooks/useCustomToast";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { uniq } from "lodash";
import { useStarredBuilders } from "queries/discover/useStarredBuilders";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useRootStore } from "store";
import BridgeMissionSelector from "views/Browse/BridgeMissionSelector";
import StartHiringToast from "views/Browse/StartHiringToast";

interface BrowseContextProviderProps {
  onStarBuilder: () => void;
}

const BrowseContext = createContext<BrowseContextProviderProps>({
  onStarBuilder: () => {},
});

export const BrowseToastProvider: React.FC = ({ children }) => {
  const {
    uiStore: { hideToast },
  } = useRootStore();
  const { modals } = useGlobalModals();
  const { data, isInitialLoading, dataUpdatedAt } = useStarredBuilders({});
  const builderIdsHash = useMemo(() => {
    if (!data?.builders) {
      return null;
    }
    const builderIds = data?.builders.sort().map(({ uid }) => uid);

    return getObjectHash(builderIds);
  }, [data?.builders, dataUpdatedAt]);

  const [showModal, setShowModal] = useState(false);
  const [seenToast, setSeenToast] = useState(isInitialLoading);
  const [hasNewBuilders, setHasNewBuilders] = useState(false);
  const [ignoreToasts, setIgnoreToasts] = useLocalStorage<Array<string>>(
    "ignoreToasts",
    []
  );

  useEffect(() => {
    if (hasNewBuilders) {
      setSeenToast(false);
      setHasNewBuilders(false);
    }
  }, [hasNewBuilders, builderIdsHash]);

  const onHide = () => {
    hideToast();

    if (builderIdsHash) {
      setIgnoreToasts(uniq([...ignoreToasts, builderIdsHash]));
    }
  };

  const { showToast } = useCustomToast(
    <StartHiringToast onClick={() => setShowModal(true)} onHide={onHide} />,
    {
      toastId: "star-hiring-toast",
      closeOnClick: false,
      delay: 1e3,
      containerId: "browse-toast-container",
      autoClose: false,
    }
  );
  const { flagEnabled, isLoading: flagLoading } = useFeatureFlag(
    Flags.SpecContextBridge
  );

  const showToastProvider = flagEnabled && !flagLoading && modals.length === 0;

  useEffect(() => {
    if (
      showToastProvider &&
      !seenToast &&
      builderIdsHash &&
      ignoreToasts &&
      !ignoreToasts.includes(builderIdsHash) &&
      !!data?.size
    ) {
      showToast();
      setSeenToast(true);
    }
  }, [builderIdsHash, seenToast, ignoreToasts, showToastProvider, data?.size]);

  return (
    <BrowseContext.Provider
      value={{
        onStarBuilder: () => setHasNewBuilders(true),
      }}
    >
      {showToastProvider && (
        <CustomToastProvider containerId={"browse-toast-container"} />
      )}
      {children}
      <BridgeMissionSelector
        onClose={() => setShowModal(false)}
        isOpen={showModal}
      />
    </BrowseContext.Provider>
  );
};

export const useBrowseContext = () =>
  useContext<BrowseContextProviderProps>(BrowseContext);
