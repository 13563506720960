import { CustomTheme } from "@a_team/ui-components";
import { useTheme } from "@emotion/react";
import { CSSObject } from "@emotion/serialize";

type IPropertiesToAdd<T extends {}> = T & CustomTheme;

export type ICSSRules = Record<string, CSSObject>;

export type CSSRulesResolver<T = {}, R = Record<string, CSSObject>> = (
  args: IPropertiesToAdd<T>
) => R;

export function useCSSRulesWithTheme<T, R>(
  getCSSRules: CSSRulesResolver<T, R>,
  args: T = {} as T
): R {
  const theme = useTheme();

  return getCSSRules({ ...args, ...theme });
}
