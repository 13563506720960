import { HiringTimeline } from "@a_team/models/dist/ClientRegistration";
import { SelectOption } from "@a_team/ui-components";

export const whenToStartOptions: (SelectOption & {
  value: HiringTimeline;
})[] = [
  {
    value: HiringTimeline.Immediate,
    label: "We need people to start immediately",
    shortLabel: "Immediately",
  },
  {
    value: HiringTimeline.NextMonth,
    label: "We need people to start next month",
    shortLabel: "Next month",
  },
  {
    value: HiringTimeline.Within3Months,
    label: "We need people to start within 3 months",
    shortLabel: "Within 3 months",
  },
  {
    value: HiringTimeline.Exploring,
    label: "We're just exploring",
    shortLabel: "Just exploring",
  },
];

export enum TimezoneOverlapValues {
  NONE = "No overlap",
  TWO_HOURS = "2 hours",
  FOUR_HOURS = "4 hours",
  SIX_HOURS = "6 hours",
  EIGHT_HOURS = "8 hours",
}

export const timeOverlapOptions: (SelectOption & {
  value: TimezoneOverlapValues;
})[] = [
  {
    value: TimezoneOverlapValues.NONE,
    label: "No overlap",
  },
  {
    value: TimezoneOverlapValues.TWO_HOURS,
    label: "2 hours",
  },
  {
    value: TimezoneOverlapValues.FOUR_HOURS,
    label: "4 hours",
  },
  {
    value: TimezoneOverlapValues.SIX_HOURS,
    label: "6 hours",
  },
  {
    value: TimezoneOverlapValues.EIGHT_HOURS,
    label: "8 hours",
  },
];

export const minimumCommitmentOptions = [
  {
    label: "Full time",
    options: [
      {
        label: "40h+ per week",
        value: 40,
        mappedValue: { min: 40, max: Infinity },
        shortLabel: "40h+ per week",
      },
    ],
  },
  {
    label: "Flexible hours",
    options: [
      {
        label: "Minimum 5h per week",
        value: 5,
        mappedValue: { min: 5, max: 10 },
        shortLabel: "5h+ per week",
      },
      {
        label: "Minimum 10h per week",
        value: 10,
        mappedValue: { min: 10, max: 20 },
        shortLabel: "10h+ per week",
      },
      {
        label: "Minimum 20h per week",
        value: 20,
        mappedValue: { min: 20, max: 30 },
        shortLabel: "20h+ per week",
      },
      {
        label: "Minimum 30h per week",
        value: 30,
        mappedValue: { min: 30, max: 40 },
        shortLabel: "30h+ per week",
      },
    ],
  },
];
