/** @jsxImportSource @emotion/react */

import { Button, Typography } from "@a_team/ui-components";
import analytics from "analytics";
import { TOAST_CLICKED, TOAST_VIEWED } from "analytics/events";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import useShortlistSpecData from "hooks/useShortlistSpecData";
import { map } from "lodash";
import { FC, MouseEventHandler, useEffect } from "react";
import { useHistory } from "react-router-dom";
import StarredBuildersList from "views/Browse/StarredBuildersList";
import closeButton from "./assets/close.svg";

export interface StartHiringToastProps {
  onHide(): void;
  onClick(): void;
}

const getCSSRules: CSSRulesResolver = ({ colors, breakpoints }) => ({
  banner: {
    backgroundColor: colors.Grey[700],
    padding: "12px 16px",
    gap: 16,
    width: "100%",
    boxSizing: "border-box",
    color: colors.Grey[0],
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      borderRadius: 4,
      maxWidth: 700,
      display: "flex",
    },
  },
  description: {
    lineHeight: "18px",
  },
  btn: {
    marginRight: 16,
    whiteSpace: "nowrap",
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      marginTop: 16,
      width: "100%",
    },
  },
  close: {
    position: "absolute",
    top: 4,
    right: 4,
    cursor: "pointer",
    padding: 4,
  },
  hideMobile: {
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      display: "none",
    },
  },
});

const StartHiringToast: FC<StartHiringToastProps> = ({ onHide, onClick }) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const history = useHistory();
  const trackingParams = {
    name: "Start Hiring Toast",
    context: history.location.pathname,
  };
  const { isLoading, data } = useShortlistSpecData();

  useEffect(() => {
    if (!isLoading) {
      analytics.track(TOAST_VIEWED, trackingParams);
    }
  }, [isLoading]);

  if (isLoading) {
    return null;
  }

  const handleClose: MouseEventHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onHide();
  };
  const handleButtonClick = (e: React.MouseEvent<any>) => {
    e.stopPropagation();

    const rolesAdded = data ? map(data.roles, "category.title") : [];

    analytics.track(TOAST_CLICKED, {
      rolesAdded,
      ...trackingParams,
    });

    onHide();
  };

  return (
    <div css={styles.banner} onClick={handleButtonClick}>
      <img
        onClick={handleClose}
        src={closeButton}
        css={styles.close}
        alt="close button"
      />
      <div css={styles.hideMobile}>
        <StarredBuildersList numberOfBuilders={2} />
      </div>
      <div>
        <Typography variant="textMedium" weight="bold" component="div">
          It's time to start hiring
        </Typography>
        <Typography
          variant="textSmall"
          weight="regular"
          component={"div"}
          css={styles.description}
        >
          Tell us what you want to build and we'll invite your shortlisted
          builders to apply to join your team.
        </Typography>
      </div>
      <div>
        <Button
          disabled={isLoading}
          onClick={() => {
            onClick();
            onHide();
          }}
          css={styles.btn}
          size="md"
        >
          Start hiring
        </Button>
      </div>
    </div>
  );
};

export default StartHiringToast;
