import { ReactChild } from "react";
import { Slide, ToastOptions } from "react-toastify";
import { useRootStore } from "store";

export interface CustomToastProps extends ToastOptions {
  once?: boolean;
  toastId?: string;
}

export default function useCustomToast(
  component: ReactChild,
  options: ToastOptions & { once?: boolean } = {}
) {
  const {
    uiStore: { setToast, hideToast },
  } = useRootStore();

  const showToast = () => {
    setToast(
      {
        text: component,
      },
      {
        style: {
          margin: 0,
          padding: 0,
        },
        autoClose: 1e4,
        transition: Slide,
        icon: false,
        hideProgressBar: true,
        bodyClassName: "",
        position: "bottom-center",
        ...options,
      }
    );
  };

  return { showToast, hideToast };
}
